import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Badge, Button } from 'reactstrap'
import { slugify } from '../util/utilityFunctions'
import "katex/dist/katex.min.css"
import { GlobalStyle } from '../util/styles'
import {Im} from '../components/PageTitle/styles'
import john from '../images/homotopia.png'
import {Ptd, Ptd2} from '../components/PageTitle/styles'

const tagsPage = ({ pageContext }) => {
  const { tags, tagPostCounts } = pageContext
  return (
    <>
    <Im src={john}  />
    <Ptd2 >
          </Ptd2>
    <Layout pageTitle="All tags">
      <GlobalStyle></GlobalStyle>
      <SEO title="All tags" keywords={['tags', 'topics']} />
      <ul>
        {tags.map(tag => (
          <li key={tag} style={{ marginBottom: '10px' }}>
            <Button color="primary" href={`/tag/${slugify(tag)}`}>
              <h8>{tag}</h8> <Badge color="light">{tagPostCounts[tag]}</Badge>
            </Button>
            
          </li>
        ))}
      </ul>
    </Layout>
    </>
  )
}

export default tagsPage
